/* Define a global namespace called "tfk" (which stands for tafkan). This
namespacing pattern is called "Self-Executing Anonymous Function with Public &
Private Members". To define a private member, simply do not use assignment
syntax. That way, whatever you define will only be accessible from inside this
closure, hence private. */
// eslint-disable-next-line no-unused-vars, no-shadow-restricted-names
(function (tfk, $, undefined) {
  tfk.assertInt = function (obj) {
    if (!_.isInteger(obj)) {
      console.trace();
      throw 'Expected integer, got ' + typeof obj;
    }
  };

  tfk.assertIntGteZero = function (obj) {
    tfk.assertInt(obj);
    if (obj < 0) {
      console.trace();
      throw 'Expected integer >= 0, got ' + obj;
    }
  };

  tfk.assertLength = function (obj, expectedLen) {
    var len = obj.length;
    if (len !== expectedLen) {
      console.trace();
      throw 'Expected length ' + expectedLen + ', got ' + len;
    }
  };

  /* `clearInputs` takes a jquery object representing a set of form input
  elements, and clears them. Note that clearing a form input means something
  different depending on the type of the input. -Jared 2012-01-13 */
  tfk.clearInputs = function (elms) {
    var textType = elms.filter('select,textarea,input[type="text"]').filter(function () {
      return $(this).val() != '';
    });
    textType.val('');
    var checkType = elms.filter('input[type="checkbox"]:checked,input[type="radio"]:checked');
    checkType.prop('checked', false);
    return textType.add(checkType);
  };

  // See explanation of setData below
  tfk.getData = function (path) {
    return _.get(tfk.data || {}, path);
  };

  tfk.grmNounArticle = function (noun) {
    var n = noun.trim().toLowerCase();
    var article = 'a';

    /* The general rule: the first character is a vowel - Jared 2012-04-18 */
    if (/[aeiou]/.test(n[0])) {
      article = 'an';
    }

    /* Exception: begins with a 'u' that sounds like a consonant - Jared 2012-04-18 */
    var consonantUSounds = /^union|unit|user$/;
    if (consonantUSounds.test(n)) {
      article = 'a';
    }

    return article;
  };

  tfk.listAppend = function (list, elm, delim) {
    delim = typeof delim != 'undefined' ? delim : ',';
    return list.trim() == '' ? elm : list + delim + elm;
  };

  tfk.passwordIsLongEnough = function (password, minLength) {
    return password.length >= minLength;
  };

  tfk.passwordIsComplexEnough = function (password, minBuckets) {
    minBuckets = minBuckets <= 4 ? minBuckets : 4;

    if (minBuckets) {
      var hasUpper = password.search(/[A-Z]/) >= 0 ? true : false;
      var hasLower = password.search(/[a-z]/) >= 0 ? true : false;
      var hasNumber = password.search(/[0-9]/) >= 0 ? true : false;
      var hasSpecial = password.search(/[^0-9a-zA-Z]/) >= 0 ? true : false;

      var bucketsFilled = hasUpper + hasLower + hasNumber + hasSpecial;
      return bucketsFilled >= minBuckets;
    }

    return true;
  };

  /* It is common to pass data from CF to JS by setting a variable. To avoid
  polluting the global namespace, `tfk.data` should be used. Use this function
  to merge your data into `tfk.data` without clobbering other people's data.

  ```
  window.tfk.setData({
    myForm: {
      myIds: <cfoutput>#SerializeJSON(rsMyData["ID"])#</cfoutput>
    }
  });
  ```
  */
  tfk.setData = function (object) {
    tfk.data = _.merge(tfk.data || {}, object);
  };
})((window.tfk = window.tfk || {}), jQuery);
